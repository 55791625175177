import React from "react";
import "./solution.scss";
// import finerPrint from "../../assets/images/fingerprint.png";
import atmCard from "../../assets/images/credit-card.png";
import shield from "../../assets/images/shield.png";
import download from "../../assets/images/download.png";
import docket from "../../assets/images/lock.png";
import cloud from "../../assets/images/cloud-service.png";

interface contents {
  id: string | any;
  title: string;
  content: string;
  icon?: React.ReactNode | any;
  space?: string;
}

const Solution = () => {
  const cardContent: contents[] = [
    // {
    //   id: "1",
    //   title: "Biodata Capture Technology",
    //   content:
    //     "The bio-data capture app will capture the student and parent’s data with a timestamp of data entry. This data is stored on the cloud for accessibility.",
    //   icon: <img src={finerPrint} className="h-20 w-20" alt="finger print" />,
    // },
    {
      id: "2",
      title: "MiFare ID Cards for NFC Tap Identification Technology",
      content:
        "Each parent will be assigned a MiFare identity card that will be used on the mobile clocking in/out device to log children or ward in and out of the system. There is also the option of a unique digital QR code generated for each parent that can be read by the app using the camera of the school’s mobile device.",
      icon: <img src={atmCard} className="h-20 w-20" alt="finger print" />,
    },
    {
      id: "3",
      title: "Data Encryption Technology",
      content:
        "The TANDA SECURE SCHOOL APP uses a highly secure data encryption technology protected from third-party unauthorized access. It uses a complex algorithm to secure the students’ and parents’ data and can only be accessed by the authorised personnel. ",
      icon: <img src={shield} className="h-20 w-20" alt="finger print" />,
      space: "mt-14",
    },
    {
      id: "4",
      title: "Flexible Reporting and Data Download",
      content:
        "The app features a comprehensive reporting system for stakeholders and a data download feature for all recorded data.",
      icon: <img src={download} className="h-20 w-20" alt="finger print" />,
    },
    {
      id: "5",
      title: "Read Only",
      content:
        "The data security app also provides a read-only solution that is not writable for a person with limited access. This feature limits tampering of data without the rightly approved access.",
      icon: <img src={docket} className="h-20 w-20" alt="finger print" />,
    },
    {
      id: "6",
      title: "Cloud Solution",
      content:
        "The cloud is the safest place to keep and retrieve data in this digital age. Real-time monitoring with real-time tracking is also synchronized with cloud storage solutions. ",
      icon: <img src={cloud} className="h-20 w-20" alt="finger print" />,
    },
  ];

  return (
    <section
      className="solution overflow-hidden pb-12 mb-7 mt-7 pt-7"
      id="solution"
    >
      <div className="container mx-auto pt-4">
        <div className="grid grid-cols-1 gap-16 lg:grid lg:grid-cols-3 md:grid md:grid-cols-2 lg:gap-y-10 lg:gap-x-2 lg:items-center md:items-center xl:grid xl:grid-cols-4 xl:gap-x-2 relative">
          {/* CARD */}
          {cardContent.map((i, index) => (
            <div
              className="bg-regularWhite mx-auto card w-[320px] h-[480px] border border-regularWhite"
              key={i.id}
              data-aos={`${index % 2 === 0 ? "flip-left" : "flip-right"}`}
            >
              <div className="flex flex-col space-y-8 py-8 px-8">
                <>{i.icon}</>

                <h6 className="pt-3 font-InterSemiBold break-words w-full">
                  {i.title}
                </h6>

                <p
                  className={`w-full font-InterRegular font-normal text-sm leading-6 text-regularGrey `}
                  style={{ marginTop: `${i.space && "58px"}` }}
                >
                  {i.content}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Solution;
