import React from "react";
import HeroImage from "../../assets/images/Hero-Image.png";
import "./about.scss";

const About = () => {
  return (
    <section
      className="about overflow-hidden bg-regularWhite mt-5 pb-5"
      id="about"
    >
      <div className="container mx-auto mt-5 pt-5 pb-5 flex flex-col items-center md:flex md:flex-col lg:flex lg:flex-row lg:space-x-12 lg:pt-0 lg:pb-5  md:items-center">
        {/* PUPILS */}
        <div
          className="lg:w-1/2"
          data-aos="fade-down-right"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          <img src={HeroImage} className="" alt="" />
        </div>

        {/* ABOUT */}
        <div
          className="w-full lg:w-1/2 flex flex-col items-center space-y-4 pt-8"
          data-aos="fade-down-left"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          <h2 className="text-regularBlack pb-4 lg:text-2xl lg:leading-9 font-extrabold font-InterBold lg:w-full tanda">
            About TANDA Secure School App
          </h2>

          <p className="w-4/5 text-justify lg:w-full font-InterRegular font-normal text-base text-regularGrey lg:text-sm lg:leading-7 lg:text-justify">
            TANDA SECURE SCHOOL APP is a solution designed for schools,
            churches, and other religious and educational institutions to reduce
            the risk of kidnapping of students, pupils, or wards. The app is
            designed to send alerts to parents and school administrators via
            email and mobile app when wards are picked up or dropped off from
            home or school.
          </p>

          <p className="w-4/5 text-justify lg:w-full font-InterRegular font-normal text-regularGrey  text-base lg:text-sm lg:leading-7 lg:text-justify">
            Wards can now safely go to and return safely with all stakeholders
            able to track their movement. Both parents and school administrators
            get instant updates on every child’s location via mobile and web
            apps.
          </p>

          <p className="w-4/5 text-justify lg:w-full font-InterRegular font-normal text-regularGrey  text-base lg:text-sm lg:leading-7">
            This software solution comprises several features, including:
          </p>
        </div>
      </div>

      {/* <Solution /> */}
    </section>
  );
};

export default About;
