import React from "react";
import Footer from "../Footer/footer";
import Header from "../Header/Header";
import Latest from "./Latest";
import "./layout.scss";

type Props = {
  children: React.ReactNode;
  body?: React.ReactNode;
};

function BlogLayout({ children, body }: Props) {
  const Banner = (): JSX.Element => {
    return (
      <div className="w-full flex flex-row items-center justify-center h-96 blog-banner mb-6">
        <h2 className="font-InterExtraBold font-extrabold text-regularWhite text-4xl leading-10">
          Explore exciting articles just for you!
        </h2>
      </div>
    );
  };
  return (
    <>
      <Header query="Blog" />
      {/* BANNER IMAGE */}
      <Banner />
      {/* BODY  --> MOVED TO COMPONENT LEVEL AS CHILDREN*/}
      <section className="overflow-hidden" id="blog-layout">
        <div className="container mx-auto text-left items-start mb-14 lg:mt-20">
          {children}
          <Latest body={body} />
        </div>
      </section>
      <Footer />
    </>
  );
}

export default BlogLayout;
