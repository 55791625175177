import React, { useEffect, useRef, useState } from "react";
import ReactGA from "react-ga4";
import { AiOutlineClose } from "react-icons/ai";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link } from "react-router-dom";
import TandaSecure from "../../assets/images/Group.png";
import { getApiBaseUrl, getBaseUrl, registerUser } from "../../utils/baseUrl";
import "./header.scss";

interface linkProps {
  id: number;
  name: string;
  link: string;
}

type Props = {
  query?: string;
};
const Header = ({ query = "" }: Props) => {
  const closeButtonRef = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const init = async () => {
      const { Offcanvas, Ripple, initTE } = await import("tw-elements");
      initTE({ Ripple, Offcanvas });
    };
    init();
  }, []);

  const baseApi = getApiBaseUrl();

  const Links: linkProps[] =
    baseApi === "https://tanda-secure-school-app.herokuapp.com/api/v1"
      ? [
          {
            id: 1,
            name: "Product Features",
            link: `${getBaseUrl()}/product-features`,
          },
          { id: 2, name: "How it Works", link: `${getBaseUrl()}/how-it-works` },
          { id: 3, name: "FAQ", link: `${getBaseUrl()}/faq` },
          { id: 4, name: "Contact us", link: `${getBaseUrl()}/contact-us` },
          { id: 5, name: "Blog", link: `${getBaseUrl()}/blog` },
        ]
      : [
          {
            id: 1,
            name: "Product Features",
            link: `${getBaseUrl()}/product-features`,
          },
          { id: 2, name: "How it Works", link: `${getBaseUrl()}/how-it-works` },
          { id: 3, name: "FAQ", link: `${getBaseUrl()}/faq` },
          { id: 4, name: "Contact us", link: `${getBaseUrl()}/contact-us` },
        ];

  return (
    <>
      <div
        className={`${
          isSticky
            ? "bg-regularWhite z-50 overflow-hidden  drop-shadow-sm sticky top-0 transition-all duration-500 ease-in-out"
            : ""
        }`}
      >
        <nav
          className="container hidden md:hidden lg:block xl:block mx-auto relative z-10"
          id="header"
        >
          <div className="font-InterMedium text-regularBlack w-full lg:flex lg:flex-row lg:items-center lg:justify-between md:justify-around">
            <div className="md:py-8 lg:flex lg:flex-row lg:pt-5 lg:pb-3 lg:space-x-10">
              <Link to={"/"}>
                <img
                  src={TandaSecure}
                  alt="secure app logo"
                  className="pt-5 pl-5 md:pt-0 md:pl-5 lg:pt-0 lg:pl-0"
                />
              </Link>

              <ul
                className={`lg:flex lg:flex-row lg:items-center ${
                  open
                    ? "slide-from-top flex flex-col md:flex md:flex-col md:space-y-8 md:pt-10 z-1 absolute bg-regularWhite w-full h-screen transition-all duration-500 ease-in"
                    : "hidden slide-from-bottom"
                }`}
              >
                <>
                  {Links.map((link) => (
                    <li
                      key={link.id}
                      className={`md:ml-4 text-base lg:text-base md:text-xl md:my-0 my-2 px-4 md:px-14 lg:px-3 font-InterMedium text-regularBlack leading-10 txt active:text-regularRed ${
                        query === link.name ? "text-regularRed" : ""
                      }`}
                    >
                      <a
                        href={link.link}
                        className={`text-gray-800 hover:text-regularRed duration-500 active:text-regularRed ${
                          query === link.name ? "text-regularRed" : ""
                        }`}
                      >
                        {link.name}
                      </a>
                    </li>
                  ))}
                  {open && (
                    <>
                      <li className="mt-10 px-2 md:px-14">
                        <a
                          href={`${registerUser()}/login`}
                          className="text-regularBlack hover:text-regularRed duration-500 no-underline text-base md:text-base font-InterRegular px-4 md:px-4"
                          target={"_blank"}
                          rel="noreferrer"
                          onClick={() => {
                            ReactGA.event({
                              category: "Button Click",
                              action: "Login",
                              label: `${document.title}-login`,
                            });
                          }}
                        >
                          Login
                        </a>
                      </li>

                      <li className="my-4 md:mt-8 px-4 md:px-16">
                        <a
                          href={`${registerUser()}/signup`}
                          className={`btn rounded-[3px] border border-solid border-regularGreen bg-regularGreen px-3 text-center py-3 font-InterMedium text-base  leading-4 text-regularWhite transition-all duration-500 ease-in no-underline`}
                          target={"_blank"}
                          rel="noreferrer"
                          onClick={() => {
                            ReactGA.event({
                              category: "Button Click",
                              action: "Create account",
                              label: `${document.title}-Signup`,
                            });
                          }}
                        >
                          Create free account
                        </a>
                      </li>
                    </>
                  )}
                </>
              </ul>
            </div>

            <div
              onClick={() => setOpen(!open)}
              className="text-3xl md:text-4xl md:pt-5 md:pr-8 absolute right-8 top-6 cursor-pointer md:block lg:hidden"
            >
              {open ? (
                <AiOutlineClose color="#19BA93" />
              ) : (
                <GiHamburgerMenu color="#19BA93" />
              )}
            </div>

            <div
              className={`md:hidden lg:flex lg:flex-row lg:items-center lg:space-x-8 ${
                open ? "hidden" : "hidden"
              }`}
            >
              <a
                href={`${registerUser()}/login`}
                className="text-gray-800 hover:text-regularRed duration-500 no-underline"
                target={"_blank"}
                rel="noreferrer"
                onClick={() => {
                  ReactGA.event({
                    category: "Button Click",
                    action: "Login",
                    label: `${document.title}-login`,
                  });
                }}
              >
                Login
              </a>

              <a
                href={`${registerUser()}/signup`}
                className={`btn rounded-[3px] border border-solid border-regularGreen bg-regularGreen px-3 text-center py-3 font-InterMedium text-base  leading-4 text-regularWhite transition-all duration-500 ease-in no-underline`}
                target={"_blank"}
                rel="noreferrer"
                onClick={() => {
                  ReactGA.event({
                    category: "Button Click",
                    action: "Create account",
                    label: `${document.title}-Signup`,
                  });
                }}
              >
                Create free account
              </a>
            </div>
          </div>
        </nav>
      </div>

      <nav
        className={`md:container md:mx-auto lg:container lg:mx-auto xl:container xl:mx-auto relative py-7 flex flex-row items-center justify-between px-5 md:px-5 lg:px-5 xl:px-5 lg:hidden xl:hidden`}
      >
        <Link to={"/"}>
          <img
            src={TandaSecure}
            alt="secure app logo"
            className=" md:pt-0 md:pl-5 lg:pt-0 lg:pl-0"
          />
        </Link>
        {/* <GiHamburgerMenu
          color="#19BA93"
          style={{ fontSize: "20px" }}
          role="button"
          data-te-offcanvas-toggle
          data-te-target="#offcanvasExample"
          aria-controls="offcanvasExample"
          data-te-ripple-init
          data-te-ripple-color="light"
        /> */}
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          className="block md:block lg:hidden xl:hidden"
          xmlns="http://www.w3.org/2000/svg"
          data-te-offcanvas-toggle
          data-te-target="#offcanvasRight"
          aria-controls="offcanvasRight"
          data-te-ripple-init
          data-te-ripple-color="light"
        >
          <path
            d="M3 6C3 5.73478 3.10536 5.48043 3.29289 5.29289C3.48043 5.10536 3.73478 5 4 5H20C20.2652 5 20.5196 5.10536 20.7071 5.29289C20.8946 5.48043 21 5.73478 21 6C21 6.26522 20.8946 6.51957 20.7071 6.70711C20.5196 6.89464 20.2652 7 20 7H4C3.73478 7 3.48043 6.89464 3.29289 6.70711C3.10536 6.51957 3 6.26522 3 6ZM20 11H4C3.73478 11 3.48043 11.1054 3.29289 11.2929C3.10536 11.4804 3 11.7348 3 12C3 12.2652 3.10536 12.5196 3.29289 12.7071C3.48043 12.8946 3.73478 13 4 13H20C20.2652 13 20.5196 12.8946 20.7071 12.7071C20.8946 12.5196 21 12.2652 21 12C21 11.7348 20.8946 11.4804 20.7071 11.2929C20.5196 11.1054 20.2652 11 20 11ZM20 17H4C3.73478 17 3.48043 17.1054 3.29289 17.2929C3.10536 17.4804 3 17.7348 3 18C3 18.2652 3.10536 18.5196 3.29289 18.7071C3.48043 18.8946 3.73478 19 4 19H20C20.2652 19 20.5196 18.8946 20.7071 18.7071C20.8946 18.5196 21 18.2652 21 18C21 17.7348 20.8946 17.4804 20.7071 17.2929C20.5196 17.1054 20.2652 17 20 17Z"
            fill="#19BA93"
          />
        </svg>
      </nav>

      <div
        className="invisible fixed bottom-0 right-0 top-0 z-[1045] flex w-96 max-w-full translate-x-full flex-col border-none bg-white bg-clip-padding text-neutral-700 shadow-sm outline-none transition duration-300 ease-in-out dark:bg-BeautyBlack dark:text-neutral-200 [&[data-te-offcanvas-show]]:transform-none"
        tabIndex={-1}
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
        data-te-offcanvas-init
      >
        <div className="flex items-center justify-between p-4">
          <button
            type="button"
            className="box-content rounded-none border-none opacity-50 hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
            data-te-offcanvas-dismiss
            ref={closeButtonRef}
          >
            <span className="w-[1em] focus:opacity-100 disabled:pointer-events-none disabled:select-none disabled:opacity-25 [&.disabled]:pointer-events-none [&.disabled]:select-none [&.disabled]:opacity-25">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#fff"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </span>
          </button>
        </div>
        <div className="offcanvas-body flex-grow overflow-y-auto overflow-x-hidden p-4">
          <ul className="w-96">
            {Links.map((link) => (
              <li
                key={link.id}
                className={`md:ml-4 text-base lg:text-base md:text-xl md:my-0 my-2 px-4 md:px-14 lg:px-3 font-InterMedium text-regularBlack leading-10 txt active:text-regularRed ${
                  query === link.name ? "text-regularRed" : ""
                }`}
              >
                <a
                  href={link.link}
                  className={`text-gray-800 hover:text-regularRed duration-500 active:text-regularRed ${
                    query === link.name ? "text-regularRed" : ""
                  }`}
                >
                  {link.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Header;
