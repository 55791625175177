let hostURI: string | any = window.location.hostname;
let testUrl: string | any = process.env.TEST_URL || "http://localhost:3000";
let stagingUrl: string | any =
  process.env.STAGING_URL || "https://secure-school-landing.netlify.app";

let liveUrl: string | any = process.env.LIVE_URL || "https://tandasecure.com";

let hostUrlName: string | any = window.location.hostname;

let loginTestUrl = "https://secure-school-admin.netlify.app";
let loginLiveUrl = "https://app.tandasecure.com";

export const baseUrl: string | any =
  hostURI === "localhost" || "secure-school-landing.netlify.app"
    ? testUrl
    : stagingUrl;

export const registerUser = () => {
  if (hostUrlName === "localhost") {
    return loginTestUrl;
  } else if (hostUrlName === "secure-school-landing.netlify.app") {
    return loginTestUrl;
  } else {
    return loginLiveUrl;
  }
};

export const getBaseUrl = () => {
  if (hostURI === "localhost") {
    return testUrl;
  } else if (hostURI === "secure-school-landing.netlify.app") {
    return stagingUrl;
  } else {
    return liveUrl;
  }
};

export const getApiBaseUrl = () => {
  if (hostURI === "localhost") {
    return "https://tanda-secure-school-app.herokuapp.com/api/v1";
  } else if (hostURI === "secure-school-landing.netlify.app") {
    return "https://tanda-secure-school-app.herokuapp.com/api/v1";
  } else {
    return "https://api.tandasecure.com/api/v1";
  }
};
