import React from "react";
// import { HashLink } from "react-router-hash-link";
import ReactGA from "react-ga4";
import Bus from "../../assets/images/bus.png";
import { getBaseUrl, registerUser } from "../../utils/baseUrl";
import "./hero.scss";

const Hero = () => {
  return (
    <section className="hero overflow-hidden" id="hero">
      <div className="container mx-auto mt-5 pt-5 pb-5 flex flex-col-reverse md:flex md:flex-col-reverse lg:mt-1 lg:pt-1 lg:flex lg:flex-row  md:items-center">
        {/* TANDA DESCRIPTION */}
        <div className="w-4/5 lg:w-1/2 hero-text flex flex-col space-y-8 items-center text-center lg:flex lg:flex-col lg:space-y-6 lg:mx-auto lg:items-center lg:text-left">
          {/* SCHOOL BUS */}
          {/* <div className="w-1/2 animated block pb-4 lg:hidden">
            <img src={Bus} className="pl-3" alt="school bus" />
          </div> */}

          <h2 className="font-InterExtraBold text-regularBlack text-base text-left w-full md:text-center md:pt-8 lg:text-3xl text-inherit lg:w-3/4 lg:leading-9 lg:pt-0 lg:mt-0 font-extrabold">
            Track Your Child’s Pickup and Drop off through your mobile with
            <span className="text-regularRed"> TANDA SECURE </span>
          </h2>

          <p className="pt-4 text-regularGrey  lg:w-4/5 font-normal font-InterRegular text-base">
            Parents and Schools get real time notifications when their children
            and staff arrive and depart school daily with Tandasecure School App
          </p>

          <p
            className="text-regularGrey lg:w-full font-normal font-InterRegular text-base p hidden"
            hidden
          >
            Tanda Secure School App is the answer.
          </p>

          <div className="flex flex-row space-x-4 pt-5 lg:w-4/5">
            <a
              href={`${getBaseUrl()}/how-it-works`}
              className="button rounded-[3px] border border-solid border-regularGreen bg-regularWhite px-3 text-center py-3 font-InterMedium text-sm lg:text-base leading-4 text-regularGreen cursor-pointer font-bold"
              onClick={() => {
                ReactGA.event({
                  category: "Button Click",
                  action: "Learn more",
                  label: `${document.title}-Home`,
                });
              }}
            >
              Learn more
            </a>

            {/* <HashLink
              smooth
              to={`/product-features/#parent-app`}
              className="button rounded-[3px] border border-solid border-regularGreen px-3 text-center py-3 font-InterMedium text-sm lg:text-base leading-4 bg-regularGreen text-regularWhite cursor-pointer font-bold"
            >
              Install TANDA
            </HashLink> */}
            <a
              href={`${registerUser()}/signup`}
              className="button rounded-[3px] border border-solid border-regularGreen px-3 text-center py-3 font-InterMedium text-sm lg:text-base leading-4 bg-regularGreen text-regularWhite cursor-pointer font-bold"
              onClick={() => {
                ReactGA.event({
                  category: "Button Click",
                  action: "Get started",
                  label: `${document.title}-Home`,
                });
              }}
            >
              Get Started
            </a>
          </div>
        </div>

        {/* SCHOOL BUS */}
        <div className="lg:w-1/2 mx-auto mt-3 lg:pt-7 pb-5">
          <img
            src={Bus}
            className="pl-3 animated lg:w-11/12"
            alt="school bus"
          />
        </div>
      </div>

      <div className="container mx-auto"></div>
    </section>
  );
};

export default Hero;
