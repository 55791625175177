import React from "react";
import { getBaseUrl } from "../../utils/baseUrl";
import "./how.scss";

const How = () => {
  return (
    <section className="how overflow-hidden bg-lightBlue mt-5 pb-5" id="how">
      <div className="container mx-auto mt-5 pt-5 pb-5 flex flex-col items-center md:flex md:flex-col lg:flex lg:flex-row lg:space-x-24 md:items-center">
        {/* PUPILS */}
        <div
          className="lg:w-1/2 xl:w-1/2"
          data-aos="fade-right"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          {/* <img
            src={Thumbnail}
            className="w-4/5 mx-auto md:w-4/5 md:mx-auto lg:w-full lg:h-auto"
            alt=""
          /> */}
          {/* <iframe
            className="school-iframe how-iframe"
            src="https://www.youtube.com/embed/GNM_IZTsY_M"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          /> */}
          <iframe
            className="school-iframe lg:w-full lg:h-64 xl:w-full xl:h-64"
            src="https://www.youtube.com/embed/5RlwXPY8kFw"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
        </div>

        {/* ABOUT */}
        <div
          className="w-full lg:w-1/2 flex flex-col items-center space-y-4 pt-8 lg:pt-1"
          data-aos="fade-left"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          <h2 className="text-regularBlack pb-4 lg:text-2xl lg:leading-9 font-extrabold font-InterBold lg:w-full tanda">
            How it Works
          </h2>

          <p className="w-4/5 text-justify lg:w-full font-InterRegular font-normal text-base text-regularGrey lg:text-sm lg:leading-7 lg:text-justify">
            Our app places an immense value on your Children's safety and
            therefore operates in four primary forms. Watch the video or click
            on the button below to learn more.
          </p>

          {/* <div className="lg:w-1/2 mt-5"> */}
          <a
            href={`${getBaseUrl()}/how-it-works`}
            className="button rounded-[3px] border border-solid border-regularGreen px-3 text-center py-3 font-InterMedium text-sm lg:text-base leading-4 bg-regularGreen text-regularWhite cursor-pointer font-bold lg:self-baseline"
          >
            Learn more
          </a>
          {/* </div> */}
        </div>
      </div>

      {/* <Solution /> */}
    </section>
  );
};

export default How;
