import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import BlogLayout from "../../component/Blog/BlogLayout";
import "./blog.scss";

function Slug(): JSX.Element {
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: `${document.title}-Blog`,
    });
  }, [location]);

  const Banner = () => {
    return (
      <div className="mt-4">
        <h2 className="font-bold font-InterBold text-regularBlack text-2xl">
          Ensuring Child Safety: A concern for all and sundry in today’s
          evolving world!
        </h2>

        <div className="my-5 relative slug-banner w-full h-96 px-4">
          {/* BOTTOM RIGHT CORNER */}
          <div
            className="absolute right-0 bottom-0 py-6 w-56"
            style={{ background: "#00000070" }}
          >
            <div className="flex flex-row w-full justify-between items-center px-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-eye cursor-pointer"
                width="30"
                height="30"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#2c3e50"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-thumb-up cursor-pointer"
                width="30"
                height="30"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#2c3e50"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M7 11v8a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-7a1 1 0 0 1 1 -1h3a4 4 0 0 0 4 -4v-1a2 2 0 0 1 4 0v5h3a2 2 0 0 1 2 2l-1 5a2 3 0 0 1 -2 2h-7a3 3 0 0 1 -3 -3" />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-share cursor-pointer"
                width="30"
                height="30"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#2c3e50"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M6 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                <path d="M18 6m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                <path d="M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                <path d="M8.7 10.7l6.6 -3.4" />
                <path d="M8.7 13.3l6.6 3.4" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Body = () => {
    return (
      <div className="basis-3/4">
        <p className="pr-4 font-InterRegular font-normal text-regularGrey text-xl leading-9 w-11/12">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
          pellentesque, diam a malesuada interdum, urna leo condimentum purus,
          non ultricies urna lectus in metus. Nunc ullamcorper vehicula
          tincidunt. Praesent consectetur lorem lectus, quis pretium sapien
          malesuada non. Sed mollis nibh sed massa lacinia, sed luctus elit
          viverra.
          <br />
          <br />
          Cras scelerisque pharetra massa et luctus. Nulla augue mauris,
          pellentesque ut augue sit amet, viverra euismod est. Pellentesque
          habitant morbi tristique senectus et netus et malesuada fames ac
          turpis egestas. Proin non eleifend tellus. Aliquam in tincidunt lacus.
          Donec sit amet ligula nec ipsum aliquam ullamcorper nec sit amet est.
          Vestibulum nec leo dictum, sodales nunc at, lacinia augue. Sed magna
          arcu, rhoncus et sem ut, auctor porttitor lorem. Ut congue sodales
          nulla, quis posuere nisi imperdiet quis.
          <br />
          <br />
          Nam sed dictum lacus. Mauris consequat nisi tellus, non pharetra nibh
          aliquet vitae. Ut fringilla dignissim velit, ac hendrerit risus
          vestibulum quis. Ut congue molestie diam sed viverra. Nulla sem
          tortor, vestibulum sed vestibulum vitae, cursus vitae velit. In hac
          habitasse platea dictumst. Ut lacinia tortor eget lorem imperdiet, in
          tincidunt augue volutpat. Aenean ac ultricies nunc, et laoreet tortor.
          Ut et leo pharetra, rutrum nisl nec, pharetra massa. Donec vitae lorem
          consequat, vehicula neque eu, fringilla diam. Fusce vel elit ac erat
          auctor finibus sit amet ut tellus. Integer egestas sit amet arcu id
          scelerisque.
          <br />
          <br />
          Cras consequat dolor eu ex rhoncus tempus vel a odio. Morbi sagittis
          at dui non lobortis. Duis vel dui at odio porttitor ultrices. Donec ut
          mattis nibh, viverra elementum diam. Aliquam ullamcorper nunc rhoncus
          augue dapibus, ultrices pulvinar ligula fringilla. Sed non nulla leo.
          Praesent pharetra orci sed convallis lacinia. Fusce nec urna ac ipsum
          tincidunt lobortis fringilla sit amet arcu. Duis ut sodales quam.
          Phasellus fermentum magna non purus viverra, in egestas nisi
          tincidunt.
          <br />
          <br />
          In massa erat, pharetra at massa non, venenatis porta enim. Praesent
          gravida est in commodo egestas. Mauris consequat nisi tellus, non
          pharetra nibh aliquet vitae. Ut fringilla dignissim velit, ac
          hendrerit risus vestibulum quis. Ut congue molestie diam sed viverra.
          Nulla sem tortor, vestibulum sed vestibulum vitae, cursus vitae velit.
          In hac habitasse platea dictumst. Ut lacinia tortor eget lorem
          imperdiet, in tincidunt augue volutpat. Aenean ac ultricies nunc, et
          laoreet tortor. Ut et leo pharetra, rutrum nisl nec, pharetra massa.
          Donec vitae lorem consequat, vehicula neque eu, fringilla diam. Fusce
          vel elit ac erat auctor finibus sit amet ut tellus. Integer egestas
          sit amet arcu id scelerisque. Cras consequat dolor eu ex rhoncus
          tempus vel a odio. Morbi sagittis at dui non lobortis. Duis vel dui at
          odio porttitor ultrices. Donec ut mattis nibh, viverra elementum diam.
          Aliquam ullamcorper nunc rhoncus augue dapibus, ultrices pulvinar
          ligula fringilla. Sed non nulla leo.
          <br />
          <br />
          Praesent pharetra orci sed convallis lacinia. Fusce nec urna ac ipsum
          tincidunt lobortis fringilla sit amet arcu. Duis ut sodales quam.
          Phasellus fermentum magna non purus viverra, in egestas nisi
          tincidunt. In massa erat, pharetra at massa non, venenatis porta enim.
          Praesent gravida est in commodo egestas. Donec vitae lorem consequat,
          vehicula neque eu, fringilla diam. Fusce vel elit ac erat auctor
          finibus sit amet ut tellus. Integer egestas sit amet arcu id
          scelerisque. Cras consequat dolor eu ex rhoncus tempus vel a odio.
          Morbi sagittis at dui non lobortis.
          <br /> <br />
          Duis vel dui at odio porttitor ultrices. Donec ut mattis nibh, viverra
          elementum diam. Aliquam ullamcorper nunc rhoncus augue dapibus,
          ultrices pulvinar ligula fringilla. Sed non nulla leo. Praesent
          pharetra orci sed convallis lacinia. Fusce nec urna ac ipsum tincidunt
          lobortis fringilla sit amet arcu. Duis ut sodales quam. Phasellus
          fermentum magna non purus viverra, in egestas nisi tincidunt. In massa
          erat, pharetra at massa non, venenatis porta enim. Praesent gravida
          est in commodo egestas.
          <br />
          <br />
          Mauris consequat nisi tellus, non pharetra nibh aliquet vitae. Ut
          fringilla dignissim velit, ac hendrerit risus vestibulum quis. Ut
          congue molestie diam sed viverra. Nulla sem tortor, vestibulum sed
          vestibulum vitae, cursus vitae velit. In hac habitasse platea
          dictumst. Ut lacinia tortor eget lorem imperdiet, in tincidunt augue
          volutpat. Aenean ac ultricies nunc, et laoreet tortor. Ut et leo
          pharetra, rutrum nisl nec, pharetra massa.
        </p>
      </div>
    );
  };
  return (
    <BlogLayout body={<Body />}>
      <Banner />
    </BlogLayout>
  );
}

export default Slug;
