import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import FAQIMAGE from "../../assets/images/FAQ-Group.png";
import "../../component/FAQ/faq.scss";
import Footer from "../../component/Footer/footer";
import Header from "../../component/Header/Header";
import { leftGrid, rightGrid } from "../../data/faq";
import "./faqScreen.scss";

const FaqNew = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: `${document.title}-FAQ`,
    });
  }, [location]);

  const faqSection = () => {
    return (
      <section className="faq overflow-hidden" id="faq">
        <div className="container mx-auto text-left items-start mb-14 lg:mt-20">
          <div className="lg:grid lg:grid-cols-2 lg:gap-3 md:grid md:grid-cols-2 md:gap-3 md:pt-0 md:pb-0 md:pl-3 md:pr-3 pb-8 grid-space lg:mb-12 lg:pb-12">
            {/* LEFT */}
            <div className="">
              <h2 className="font-InterExtraBold font-normal lg:text-4xl md:text-base lg:leading-8 text-regularBlack w-full pb-2 text-alignn lg:mt-6 lg:pb-6 text-center pt-7 md:text-left">
                Frequently Asked{" "}
                <span className="text-regularRed">Questions.</span>
              </h2>
              <div className="pt-7">
                {leftGrid.map((i) => (
                  <div
                    className="bg-lightBlue w-small lg:w-full md:4/5 h-auto flex flex-col space-y-5 border-regularGrey px-8 py-8 lg:my-2 md:my-2"
                    key={i.id}
                  >
                    <h6 className="font-semibold text-base font-InterMedium leading-7">
                      {i.Q}
                    </h6>

                    <p className="text-sm leading-5 font-InterRegular text-regularGrey font-normal">
                      {i.A}
                    </p>
                  </div>
                ))}
              </div>
            </div>

            {/* RIGHT */}
            <div>
              <img src={FAQIMAGE} className="" alt="faq" />
              {rightGrid.map((i) => (
                <div
                  className="bg-lightBlue w-small lg:w-full md:4/5 h-auto flex flex-col space-y-5 border-regularGrey px-8 py-8 lg:my-2 md:my-2"
                  key={i.id}
                >
                  <h6 className="font-semibold text-base font-InterMedium leading-7">
                    {i.Q}
                  </h6>

                  <p className="text-sm leading-5 font-InterRegular text-regularGrey font-normal">
                    {i.A}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    );
  };
  return (
    <React.Fragment>
      <Header query="FAQ" />
      {faqSection()}
      <Footer />
    </React.Fragment>
  );
};

export default FaqNew;
