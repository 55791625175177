import React from "react";
import { getBaseUrl } from "../../utils/baseUrl";
import "./faq.scss";

const Faq = () => {
  const QA = () => {
    return (
      <div className="lg:grid lg:grid-cols-2 lg:gap-3 md:grid md:grid-cols-2 md:gap-3 md:pt-0 md:pb-0 md:pl-3 md:pr-3 pb-8">
        {/* LEFT */}
        <div className="grid-left grid gap-3">
          <div
            className="bg-lightBlue w-small lg:w-full md:4/5 h-auto flex flex-col space-y-5 border-regularGrey px-8 py-8"
            key=""
            data-aos="zoom-in-down"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          >
            <h6 className="font-semibold text-base font-InterMedium leading-7">
              Q.  What are the other costs incurred by the school to provide the service?
            </h6>

            <p className="text-sm leading-5 font-InterRegular text-regularGrey font-normal">
              A: To ensure a seamless App operation, the school has to provide internet connectivity for the devices, provide competent staff to operate the devices and provide enough devices for their unique population of pupils/students. This will inform their final cost of subscription for each child.
            </p>
          </div>

          <div
            className="bg-lightBlue w-small lg:w-full md:4/5 h-auto flex flex-col space-y-5 border-regularGrey px-8 py-8"
            key=""
            data-aos="zoom-in-left"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          >
            <h6 className="font-semibold text-base font-InterMedium leading-7">
              Q: How is Tanda Secure different from the off the shelf products in the market?
            </h6>

            <p className="text-sm leading-5 font-InterRegular text-regularGrey font-normal">
              A: Tanda Secure App is unique in the sense that the Parent App gives more control to parents in real-time as to who drops-off/picks up their wards and grants more visibility to the school via the Web Admin dashboard. It is also very portable, durable and affordable.
            </p>
          </div>
        </div>

        {/* RIGHT */}
        <div className="grid-right grid gap-3">
          <div
            className="bg-lightBlue w-small lg:w-full md:4/5 h-auto flex flex-col space-y-5 border-regularGrey px-8 py-8"
            key=""
            data-aos="zoom-in-left"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          >
            <h6 className="font-semibold text-base font-InterMedium leading-7">
              Q: What is the cost of the Tanda102 device?
            </h6>

            <p className="text-sm leading-5 font-InterRegular text-regularGrey font-normal">
              A: The Tanda102 device cost $350. It is a rugged device built
              specifically for outdoor operations and is shock-proof, water
              resistant, dust proof, has long battery-life to cope with the
              rigours of large-scale time and attendance operations.
            </p>
          </div>

          <div
            className="bg-lightBlue w-small lg:w-full md:4/5 h-auto flex flex-col space-y-5 border-regularGrey px-8 py-8"
            key=""
            data-aos="zoom-in-down"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          >
            <h6 className="font-semibold text-base font-InterMedium leading-7">
              Q: Are there alternatives to the ID cards?
            </h6>

            <p className="text-sm leading-5 font-InterRegular text-regularGrey font-normal">
              A: Yes, in a true digital environment QR codes generated by the
              Tanda Secure App can be scanned in place of ID cards as the
              Parent/Guardian/Child data lives in the backend and not on the
              card.
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <section
      className="faq bg-regularWhite mt-8 pt-8 pb-12 mb-12 overflow-hidden"
      id="faq"
    >
      <div className="container mx-auto">
        <h2
          className="text-regularBlack text-center pb-4 lg:text-2xl lg:leading-9 font-extrabold font-InterBold lg:w-full mb-10 under-line mt-4"
          data-aos="zoom-in"
          data-aos-easing="ease-in-out"
        >
          Frequently Asked Questions
        </h2>
        {QA()}

        <div className="text-center pt-10 pb-10">
          <a
            href={`${getBaseUrl()}/faq`}
            className="button rounded-[3px] border border-solid border-regularGreen px-3 text-center py-3 font-InterMedium text-sm lg:text-base leading-4 bg-regularGreen text-regularWhite cursor-pointer font-bold lg:self-baseline"
          >
            Read more
          </a>
        </div>
      </div>
    </section>
  );
};

export default Faq;
