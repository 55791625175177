import React, { useState } from "react";
import { Link } from "react-router-dom";


type Props = {
  body: React.ReactNode;
};

function Latest({ body }: Props): JSX.Element {
  const [email, setEmail] = useState<string>();

  const BasisRight = (): JSX.Element => {
    return (
      <div className="basis-1/4 flex flex-col space-y-8">
        <div className="bg-regularGreen h-auto flex flex-col space-y-8 py-12 px-8">
          <h6 className="font-InterMedium font-semibold text-regularWhite text-2xl leading-10">
            Subscribe to get the latest articles right in your inbox
          </h6>

          <input
            type={"email"}
            value={email}
            onChange={(e: any) => setEmail(e.target.value)}
            className="px-3 py-5 w-4/5 lg:w-full border border-regularWhite bg-regularWhite font-InterRegular text-regularGrey text-base shadow-none outline-none placeholder:text-base placeholder:text-regularGrey"
            placeholder="Email Address"
            name="email"
            id="email"
          />

          <button className="button-footerrr rounded-[3px] border border-solid border-regularWhite px-3 text-center py-3 font-InterMedium text-sm lg:text-base leading-4 bg-regularWhite text-regularBlack cursor-pointer font-bold lg:self-baseline mb-10">
            Subscribe
          </button>
        </div>

        <div className="flex flex-col pt-16">
          <h5 className="font-medium font-InterMedium text-regularGrey text-2xl px-4 pb-4">
            Popular posts
          </h5>
          <hr className="h-1 border-0 dark:bg-regularBlack bg-regularBlack" />

          {[1, 2, 3, 4].map((i, index: any) => {
            return (
              <Link
                to={"/blog/6463d99dd0f4ca603eeafe31"}
                className={`cursor-pointer pb-4 px-4 font-InterRegular font-normal text-lg leading-9 hover:underline text-regularGrey ${
                  index === 0 ? "pt-6" : ""
                }`}
              >
                Addressing Safety Concerns: Collaborative Efforts between
                Schools and Communities
              </Link>
            );
          })}
        </div>
      </div>
    );
  };

 

  return (
    <div className="mt-12 mb-10 pt-10 pb-10">
      <div className="flex flex-row space-x-4">
        {body}
        <div className="hidden md:hidden lg:w-1/4 lg:block xl:block xl:w-1/4">
          <BasisRight />
        </div>
      </div>
    </div>
  );
}

export default Latest;
