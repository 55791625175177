import React from "react";
import Marquee from "react-fast-marquee";
import Bownie from "../../assets/images/Bowine_Logo.png";
import SilverCrest from "../../assets/images/LOGO_2.png";
import NewLand from "../../assets/images/Newland_logo.png";
import Divina from "../../assets/images/divina.jpeg";
import "./marque.css";

function Partners(): JSX.Element {
  return (
    <section className="mt-8 mb-5 overflow-hidden" id="marquee">
      <div className="container mx-auto mt-12 mb-5 py-9 flex flex-col border border-t-marqueeBorder border-b-marqueeBorder border-r-0 border-l-0 overflow-hidden">
        {/* <div className="hidden md:flex md:flex-row md:items-center md:space-x-10 lg:flex lg:flex-row lg:items-center lg:space-x-10 xl:flex xl:flex-row xl:items-center xl:space-x-10">
          <div className="hidden lg:mr-8 xl:mr-8 md:mr-6">
            <h5 className="font-InterBold font-bold opacity-100 text-base md:text-lg lg:text-3xl xl:text-3xl leading-5">
              Our Partners
            </h5>
          </div>
          <div className="flex flex-row items-center space-x-12">
            <img src={SilverCrest} className="mr-6" alt="silver-crest" />
            <img src={Bownie} alt="bownie" className="space-right" />
            <img src={NewLand} alt="newland" className="ml-6" />
          </div>
        </div>
        <div className="md:hidden lg:hidden xl:hidden">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <img
                src={SilverCrest}
                className="h-auto w-full"
                alt="silver-crest"
              />
            </div>
            <div className="flex flex-row items-center">
              <img src={Bownie} alt="bownie" className="h-auto w-full" />
            </div>
            <div>
              <img src={NewLand} alt="newland" className="h-auto w-full" />
            </div>
          </div>
        </div> */}
        <Marquee>
          <div className="lg:mr-8 xl:mr-8 md:mr-6">
            <h5 className="font-InterBold font-bold opacity-100 text-base md:text-lg lg:text-3xl xl:text-3xl leading-5">
              Our Partners
            </h5>
          </div>
          <img src={SilverCrest} className="mr-6" alt="silver-crest" />
          <img src={Bownie} alt="bownie" className="space-right" />
          <img src={NewLand} alt="newland" className="ml-6" />
          <img
            src={Divina}
            alt="newland"
            className="ml-6"
            style={{ maxWidth: "10%" }}
          />
        </Marquee>
      </div>
    </section>
  );
}

export default Partners;
