import React from "react";
import { Link } from "react-router-dom";
import BlogFeature from "../../assets/images/blogfeature.png";

const Featured = (): JSX.Element => {
  return (
    <div className="mt-4">
      <h5 className="font-medium font-InterMedium text-regularGrey text-2xl ml-12">
        Featured articles
      </h5>
      <div className="grid grid-cols-1 gap-y-8 mx-auto md:grid md:grid-cols-2 md:gap-y-8 md:gap-x-8 lg:grid lg:grid-cols-3 lg:gap-x-4 lg:gap-y-6 xl:grid xl:grid-cols-4  xl:gap-x-4 xl:gap-y-0 my-5">
        {[1,2,3,5].map(() => {
          return (
            <Link
              to={"/blog/6463d99dd0f4ca603eeafe31"}
              className="rounded-bl-md rounded-br-md rounded-tl-none rounded-tr-none w-80 mx-auto md:mx-0 xl:mx-0 lg:mx-0"
            >
              <img src={BlogFeature} className="" alt="blog_feature" />

              <div className="flex flex-col space-y-4 py-6 bg-lightBlue rounded-bl-3xl rounded-br-3xl px-6">
                <div className="w-80">
                  <h4 className="text-left w-4/5 font-medium font-InterMedium text-regularGrey text-lg leading-7 ">
                    Child safety, a big concern for all and sundry and classes
                  </h4>
                </div>
                {/* SHARE TO SOCIAL MEDIA */}
                <div className="flex flex-row space-x-2 justify-between">
                  <div className="flex flex-row space-x-4">
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-thumb-up"
                      width="30"
                      height="30"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="#2c3e50"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M7 11v8a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-7a1 1 0 0 1 1 -1h3a4 4 0 0 0 4 -4v-1a2 2 0 0 1 4 0v5h3a2 2 0 0 1 2 2l-1 5a2 3 0 0 1 -2 2h-7a3 3 0 0 1 -3 -3" />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-share"
                      width="30"
                      height="30"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="#2c3e50"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M6 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                      <path d="M18 6m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                      <path d="M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                      <path d="M8.7 10.7l6.6 -3.4" />
                      <path d="M8.7 13.3l6.6 3.4" />
                    </svg>
                  </div>
                  <div className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-eye"
                      width="30"
                      height="30"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="#2c3e50"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                      <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" />
                    </svg>
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default Featured;
