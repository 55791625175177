import React from "react";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./component";
import Blog from "./Screens/Blog/Blog";
import Slug from "./Screens/Blog/Slug";
import ContactUs from "./Screens/ContacUs/ContactUs";
import FaqNew from "./Screens/FAQ/FaqNew";
import How from "./Screens/How/How";
import Privacy from "./Screens/Privacy/Privacy";
import ProductFeatures from "./Screens/ProductFeatures/ProductFeatures";

function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />} />
          <Route path="/how-it-works" element={<How />} />
          <Route path="/faq" element={<FaqNew />} />
          <Route path="/privacy_policy" element={<Privacy />} />
          <Route path="/product-features" element={<ProductFeatures />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:slug" element={<Slug />} />
        </Routes>
      </BrowserRouter>
      <Toaster />
    </React.Fragment>
  );
}

export default App;
