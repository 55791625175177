import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { Link, useLocation } from "react-router-dom";
import BlogFeature from "../../assets/images/blogfeature.png";
import BlogLayout from "../../component/Blog/BlogLayout";
import Featured from "../../component/Blog/Featured";
import "./blog.scss";

function Blog(): JSX.Element {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: `${document.title}-Blog`,
    });
  }, [location]);

  const BasisLeft = (): JSX.Element => {
    return (
      <div className="lg:basis-3/4 xl:basis-3/4">
        <div className="w-11/12 flex flex-col space-y-2 mx-auto md:flex md:flex-row md:justify-between md:space-x-8 lg:flex lg:flex-row lg:justify-between lg:space-x-8 lg:mx-0 xl:flex xl:flex-row xl:justify-between xl:space-x-8 xl:mx-0">
          <div className="w-full md:w-1/2 md:pr-3 lg:w-1/2 xl:w-1/2 lg:pr-3 xl:pr-3">
            <img
              src={BlogFeature}
              className="w-full object-cover"
              alt="blog_headline"
            />
          </div>
          <div className="md:w-1/2 md:flex md:flex-col md:space-y-8 md:pl-3 lg:w-1/2 lg:flex lg:flex-col lg:space-y-8 lg:pl-3 xl:w-1/2 xl:flex xl:flex-col xl:space-y-8 xl:pl-3">
            <h3 className="font-InterSemiBold text-regularBlack text-2xl leading-9 font-semibold">
              Blog Post 2: Title goes here Title second line here . . .{" "}
            </h3>
            <p className="font-InterRegular text-regularGrey font-normal text-xl leading-9">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s. Lorem Ipsum has . . . .
            </p>
            <Link
              to={"/blog/123ydfsnsy222829"}
              className="uppercase font-InterBold font-bold text-regularGreen text-xl leading-9 hover:underline"
            >
              READ FULL ARTICLE
            </Link>
          </div>
        </div>
      </div>
    );
  };

  const Body = () => {
    return (
      <div className="flex flex-col w-full md:w-full lg:w-3/4 xl:w-3/4 space-y-10">
        <h5 className="font-medium font-InterMedium text-regularGrey text-2xl ml-12 mb-4">
          Latest articles
        </h5>
        {[1, 2, 3].map((i, index: any) => {
          return (
            <div className={`${index > 0 ? "mt-4" : ""}`}>
              {" "}
              <BasisLeft />
            </div>
          );
        })}
        {/* LOAD MORE */}
        <div className="mx-auto place-self-center">
          <button className="rounded-[3px] border border-solid border-regularGreen px-3 text-center py-3 font-InterMedium text-sm lg:text-base leading-4 bg-regularGreen text-regularWhite cursor-pointer font-bold lg:self-baseline">
            Load more
          </button>
        </div>
      </div>
    );
  };

  return (
    <BlogLayout body={<Body />}>
      <Featured />
    </BlogLayout>
  );
}

export default Blog;
