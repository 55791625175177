import React from "react";
import "./choose.scss";
import { Chrono } from "react-chrono";
// import { Timeline } from "../../utils/timeline";

const Choose = () => {
  // const chooseGrid = () => {
  //   return (
  //     <div className="timeline">
  //       <div
  //         className="event text-left"
  //         data-aos="flip-up"
  //         data-aos-duration="1000"
  //         data-aos-easing="ease-in-out"
  //       >
  //         <div
  //           className="lg:w-full md:4/5 h-auto flex flex-col space-y-5 px-8 py-8"
  //           key=""
  //         >
  //           <h6 className="font-semibold text-base font-InterMedium leading-7">
  //             Easy Adoption
  //           </h6>

  //           <p className="text-sm leading-5 font-InterRegular text-regularGrey font-normal">
  //             TANDA SECURE SCHOOL APP is entirely easy to learn, install and
  //             use. It also provides accurate data with real-time updates and
  //             compliance attributes. As a result, your institution can quickly
  //             adopt it without any retraining concerns.
  //           </p>
  //         </div>
  //       </div>
  //       <div className="line relative"></div>
  //       <div
  //         className="event"
  //         data-aos="flip-down"
  //         data-aos-duration="1000"
  //         data-aos-easing="ease-in-out"
  //       >
  //         <div
  //           className="lg:w-full md:4/5 h-auto flex flex-col space-y-5 px-8 py-8"
  //           key=""
  //         >
  //           <h6 className="font-semibold text-base font-InterMedium leading-7">
  //             Tested Best Practices
  //           </h6>

  //           <p className="text-sm leading-5 font-InterRegular text-regularGrey font-normal">
  //             The app comes with proven digital features to help school owners
  //             and management with higher security levels. It is also built on a
  //             robust and secure database.
  //           </p>
  //         </div>
  //       </div>
  //       <div className="line"></div>
  //       <div
  //         className="event"
  //         data-aos="flip-up"
  //         data-aos-duration="1000"
  //         data-aos-easing="ease-in-out"
  //       >
  //         <div
  //           className="lg:w-full md:4/5 h-auto flex flex-col space-y-5 px-8 py-8"
  //           key=""
  //         >
  //           <h6 className="font-semibold text-base font-InterMedium leading-7">
  //             Keeping up with the Digital Pace
  //           </h6>

  //           <p className="text-sm leading-5 font-InterRegular text-regularGrey font-normal">
  //             The digital space requires users or clients to keep up with the
  //             pace of tech advancement. Consequently, the TANDA SECURE SCHOOL
  //             APP leverages mobile and cloud technology to enhance the security
  //             of school children.
  //           </p>
  //         </div>
  //       </div>
  //       <div className="line"></div>
  //       <div
  //         className="event"
  //         data-aos="flip-down"
  //         data-aos-duration="1000"
  //         data-aos-easing="ease-in-out"
  //       >
  //         <div
  //           className="lg:w-full md:4/5 h-auto flex flex-col space-y-5 px-8 py-8"
  //           key=""
  //         >
  //           <h6 className="font-semibold text-base font-InterMedium leading-7">
  //             Better Economics
  //           </h6>

  //           <p className="text-sm leading-5 font-InterRegular text-regularGrey font-normal">
  //             This cloud-based security management app is affordable and
  //             cost-effective compared to standard security systems in terms of
  //             costs. However, it supports any existing security apparatus for
  //             more efficient service rendering.
  //           </p>
  //         </div>
  //       </div>
  //       <div className="line"></div>
  //     </div>
  //   );
  // };

  return (
    <section className="choose mt-10 pt-10 pb-10 mb-10" id="choose-us">
      <div className="container mx-auto">
        <h2
          className="text-regularBlack text-center pb-4 lg:text-2xl lg:leading-9 font-extrabold font-InterBold lg:w-full mb-10 under-line mt-4"
          data-aos="zoom-in"
          data-aos-duration="1000"
        >
          Why Choose Us?
        </h2>

        {/* {chooseGrid()} */}
        <Chrono
          mode="VERTICAL_ALTERNATING"
          borderLessCards={false}
          disableClickOnCircle
          disableNavOnKey
          hideControls
        >
          <div
            className="lg:w-full md:4/5 h-auto flex flex-col space-y-5 px-8 py-8"
            key=""
          >
            <h6 className="font-semibold text-base font-InterMedium leading-7">
              Easy Adoption
            </h6>

            <p className="text-sm leading-5 font-InterRegular text-regularGrey font-normal">
              TANDA SECURE SCHOOL APP is entirely easy to learn, install and
              use. It also provides accurate data with real-time updates and
              compliance attributes. As a result, your institution can quickly
              adopt it without any retraining concerns.
            </p>
          </div>
          <div
            className="lg:w-full md:4/5 h-auto flex flex-col space-y-5 px-8 py-8"
            key=""
          >
            <h6 className="font-semibold text-base font-InterMedium leading-7">
              Tested Best Practices
            </h6>

            <p className="text-sm leading-5 font-InterRegular text-regularGrey font-normal">
              The app comes with proven digital features to help school owners
              and management with higher security levels. It is also built on a
              robust and secure database.
            </p>
          </div>
          <div
            className="lg:w-full md:4/5 h-auto flex flex-col space-y-5 px-8 py-8"
            key=""
          >
            <h6 className="font-semibold text-base font-InterMedium leading-7">
              Keeping up with the Digital Pace
            </h6>

            <p className="text-sm leading-5 font-InterRegular text-regularGrey font-normal">
              The digital space requires users or clients to keep up with the
              pace of tech advancement. Consequently, the TANDA SECURE SCHOOL
              APP leverages mobile and cloud technology to enhance the security
              of school children.
            </p>
          </div>
          <div
            className="lg:w-full md:4/5 h-auto flex flex-col space-y-5 px-8 py-8"
            key=""
          >
            <h6 className="font-semibold text-base font-InterMedium leading-7">
              Better Economics
            </h6>

            <p className="text-sm leading-5 font-InterRegular text-regularGrey font-normal">
              This cloud-based security management app is affordable and
              cost-effective compared to standard security systems in terms of
              costs. However, it supports any existing security apparatus for
              more efficient service rendering.
            </p>
          </div>
        </Chrono>
      </div>
    </section>
  );
};

export default Choose;
