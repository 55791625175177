/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import toast from "react-hot-toast";
import { GrMapLocation } from "react-icons/gr";
import { HiOutlineMail } from "react-icons/hi";
import { useLocation } from "react-router-dom";
import { ReactComponent as Phone } from "../../assets/svgs/phone.svg";
import Footer from "../../component/Footer/footer";
import Header from "../../component/Header/Header";
import Loader from "../../component/Loader/Loader";
import { getApiBaseUrl } from "../../utils/baseUrl";
import "./contactUs.scss";

function ContactUs() {
  const [fullName, setFullName] = useState<string>("");
  const [schoolName, setSchoolName] = useState<string>("");
  const [schoolLocation, setSchoolLocation] = useState<string>("");
  const [boarders, setBoarders] = useState<string>("");
  const [dayStudents, setDayStudents] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [subject, setSubject] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [loading, setLoader] = useState<boolean>(false);

  const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: `${document.title}-Contact Us`,
    });
  }, [location]);

  const disabled =
    fullName !== "" &&
    schoolName !== "" &&
    email !== "" &&
    phone !== "" &&
    subject !== "" &&
    message !== ""
      ? false
      : true;

  const contact = async (payload: any) => {
    setLoader(true);
    try {
      const response = await axios.post(
        `${getApiBaseUrl()}/contactus`,
        {
          ...payload,
        },
        {
          headers: {
            "Content-Type": "application/json",
            secretKey: "DISC-JA7xtL0Bvm-PROD",
          },
        }
      );

      if (response.status === 200) {
        setEmail("");
        setFullName("");
        setSchoolName("");
        setSchoolLocation("");
        setBoarders("");
        setDayStudents("");
        setPhone("");
        setMessage("");
        setSubject("");
        toast.success(response.data.message, { position: "top-right" });
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      return error;
    }
    setLoader(false);
  };

  const payload = {
    name: fullName,
    subject: subject,
    emailBody: message,
    schoolName: schoolName,
    schoolLocation: schoolLocation,
    // boarderNumber: parseInt(boarders) ,
    // dayStudentNumber: parseInt(dayStudents),
    email: email,
    phoneNumber: phone,
  };

  return (
    <>
      <Header query="Contact us" />
      <section className={`overflow-hidden contact-bg`}>
        <div className="container mx-auto">
          <div className="grid grid-cols-1 lg:grid lg:grid-cols-2 md:grid md:grid-cols-2 lg:mt-14 lg:mb-10 contact-bg ">
            <div className="flex flex-col space-y-6 md:space-y-9 lg:space-y-14 pt-12 lg:pt-10 md:pt-12  md:px-3">
              <h4 className="font-InterBold font-extrabold text-center lg:text-left md:text-left lg:text-4xl leading-5 text-regularBlack md:text-lg">
                Get in touch with us
              </h4>

              <p className="font-InterRegular font-normal text-center lg:text-left md:text-left lg:text-lg leading-7 text-regularGrey lg:w-4/5 md:text-base md:w-3/4">
                Do have any question or consideration? Kindly Reach out to us.
                We’re always ready to help!
              </p>

              <div className="flex flex-row  md:space-x-3 lg:space-x-5 items-center justify-center lg:justify-start md:justify-start">
                <Phone color="#525957" />
                &nbsp;
                <span className="font-InterRegular font-normal text-base md:text-base lg:text-xl leading-6 text-regularGrey">
                  +234 905 438 0854,  +234 905 070 2617.
                </span>
              </div>

              <div className="flex flex-row space-x-5 items-center justify-center lg:justify-start md:justify-start">
                <HiOutlineMail size={30} color="#525957" />
                &nbsp;
                <span className="font-InterRegular font-normal text-base md:text-base lg:text-xl leading-6 text-regularGrey">
                  info@tandasecure.com
                </span>
              </div>

              <div className="flex flex-row space-x-5 items-center justify-center lg:justify-start md:justify-start">
                <GrMapLocation size={30} color="#525957" fill="#525957" />
                &nbsp;
                <span className="font-InterRegular font-normal text-base md:text-base lg:text-xl leading-6 w-3/4 text-regularGrey">
                  3 New Creation Street, Off Remi Olowude Street, Marwa Bus
                  Stop, Lekki, Lagos.
                </span>
              </div>
            </div>

            <div className="flex flex-col space-y-4 pb-8 md:px-6 md:pb-8 lg:pl-14 overflow-hidden lg:pb-0 lg:px-0 pt-12">
              <div className="mx-auto w-5/6 pt-12 lg:pt-0 md:pt-0 lg:w-11/12 md:w-11/12 flex flex-col space-y-5">
                <div className="flex flex-col space-y-2 w-full lg:px-0">
                  {/* <label htmlFor="email" className="py-2 lg:py-0 md:py-0">
                      Your email
                    </label> */}
                  <input
                    className="border border-solid border-regularBorder rounded-lg shadow-none outline-none py-4 px-4 bg-regularGreen text-regularWhite placeholder:text-regularWhite font-InterRegular font-normal placeholder:font-InterRegular"
                    value={fullName}
                    name="name"
                    id="name"
                    placeholder="Name"
                    onChange={(e) => setFullName(e.target.value)}
                    required
                  />
                </div>
              </div>

              <div className="mx-auto w-5/6 lg:w-11/12 md:w-11/12 flex flex-col space-y-5">
                <div className="flex flex-col space-y-2 w-full lg:px-0">
                  {/* <label htmlFor="email" className="py-2 lg:py-0 md:py-0">
                      Your email
                    </label> */}
                  <input
                    className="border border-solid border-regularBorder rounded-lg shadow-none outline-none py-4 px-4 bg-regularGreen text-regularWhite placeholder:text-regularWhite font-InterRegular font-normal placeholder:font-InterRegular"
                    value={schoolName}
                    name="name"
                    id="name"
                    placeholder="School name"
                    onChange={(e) => setSchoolName(e.target.value)}
                    required
                  />
                </div>
              </div>

              <div className="mx-auto w-5/6 lg:w-11/12 md:w-11/12 flex flex-col space-y-5">
                <div className="flex flex-col space-y-2 w-full lg:px-0">
                  {/* <label htmlFor="email" className="py-2 lg:py-0 md:py-0">
                      Your email
                    </label> */}
                  <input
                    className="border border-solid border-regularBorder rounded-lg shadow-none outline-none py-4 px-4 bg-regularGreen text-regularWhite placeholder:text-regularWhite font-InterRegular font-normal placeholder:font-InterRegular"
                    value={schoolLocation}
                    name="name"
                    id="name"
                    placeholder="School location"
                    onChange={(e) => setSchoolLocation(e.target.value)}
                    required
                  />
                </div>
              </div>

              {/* <div className="mx-auto w-5/6 lg:w-11/12 md:w-11/12 flex flex-col space-y-5">
                <div className="flex flex-col space-y-2 w-full lg:px-0">
                  <input
                    className="border border-solid border-regularBorder rounded-lg shadow-none outline-none py-4 px-4 bg-regularGreen text-regularWhite placeholder:text-regularWhite font-InterRegular font-normal placeholder:font-InterRegular"
                    value={boarders}
                    name="name"
                    id="name"
                    placeholder="Number of boarders"
                    onChange={(e) => setBoarders(e.target.value)}
                    required
                  />
                </div>
              </div> */}

              {/* <div className="mx-auto w-5/6 lg:w-11/12 md:w-11/12 flex flex-col space-y-5">
                <div className="flex flex-col space-y-2 w-full lg:px-0">
                  <input
                    className="border border-solid border-regularBorder rounded-lg shadow-none outline-none py-4 px-4 bg-regularGreen text-regularWhite placeholder:text-regularWhite font-InterRegular font-normal placeholder:font-InterRegular"
                    value={dayStudents}
                    name="name"
                    id="name"
                    required
                    placeholder="Number of day students"
                    onChange={(e) => setDayStudents(e.target.value)}
                  />
                </div>
              </div> */}

              <div className="mx-auto w-5/6 lg:w-11/12 md:w-11/12 flex flex-col space-y-5">
                <div className="flex flex-col space-y-2 w-full lg:px-0">
                  {/* <label htmlFor="email" className="py-2 lg:py-0 md:py-0">
                      Your email
                    </label> */}
                  <input
                    className="border border-solid border-regularBorder rounded-lg shadow-none outline-none py-4 px-4 bg-regularGreen text-regularWhite placeholder:text-regularWhite font-InterRegular font-normal placeholder:font-InterRegular"
                    value={email}
                    name="email"
                    id="email"
                    required
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>

              <div className="mx-auto w-5/6 lg:w-11/12 md:w-11/12 flex flex-col space-y-5">
                <div className="flex flex-col space-y-2 w-full lg:px-0">
                  {/* <label htmlFor="email" className="py-2 lg:py-0 md:py-0">
                      Your email
                    </label> */}
                  <input
                    className="border border-solid border-regularBorder rounded-lg shadow-none outline-none py-4 px-4 bg-regularGreen text-regularWhite placeholder:text-regularWhite font-InterRegular font-normal placeholder:font-InterRegular"
                    value={phone}
                    name="phone"
                    id="phone"
                    required
                    placeholder="Phone number"
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
              </div>

              <div className="mx-auto w-5/6 lg:w-11/12 md:w-11/12 flex flex-col space-y-5">
                <div className="flex flex-col space-y-2 w-full lg:px-0">
                  {/* <label htmlFor="email" className="py-2 lg:py-0 md:py-0">
                      Your email
                    </label> */}
                  <input
                    className="border border-solid border-regularBorder rounded-lg shadow-none outline-none py-4 px-4 bg-regularGreen text-regularWhite placeholder:text-regularWhite font-InterRegular font-normal placeholder:font-InterRegular"
                    value={subject}
                    name="subject"
                    id="subject"
                    placeholder="Subject"
                    required
                    onChange={(e) => setSubject(e.target.value)}
                  />
                </div>
              </div>

              <div className="mx-auto w-5/6 lg:w-11/12 md:w-11/12 flex flex-col space-y-5">
                <div className="flex flex-col space-y-2 w-full lg:px-0">
                  {/* <label htmlFor="email" className="py-2 lg:py-0 md:py-0">
                      Your email
                    </label> */}
                  <textarea
                    className="border border-solid border-regularBorder rounded-lg shadow-none outline-none py-4 px-4 bg-regularGreen text-regularWhite placeholder:text-regularWhite font-InterRegular font-normal placeholder:font-InterRegular"
                    value={message}
                    name="message"
                    id="message"
                    placeholder="Message"
                    required
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>
              </div>

              <div className="mx-auto w-5/6 lg:w-11/12 md:w-11/12 flex flex-col space-y-5">
                <div className="flex flex-col space-y-2 pt-4 w-full lg:px-0 items-center justify-center">
                  {/* <label htmlFor="email" className="py-2 lg:py-0 md:py-0">
                      Your email
                    </label> */}
                  <button
                    className="rounded-[3px] border border-solid border-regularGreen px-3 text-center py-3 font-InterMedium text-sm lg:text-base leading-4 bg-regularGreen text-regularWhite cursor-pointer font-bold h-auto flex flex-row items-center justify-center"
                    type={"button"}
                    disabled={disabled}
                    onClick={() => contact(payload)}
                  >
                    Send Message{" "}
                    {loading && (
                      <>
                        &nbsp;&nbsp;
                        <Loader />
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default ContactUs;
