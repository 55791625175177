import React, { useState } from "react";
import { getBaseUrl } from "../../utils/baseUrl";
import "./footer.scss";

const Footer = () => {
  const [email, setEmail] = useState();

  return (
    <footer className="footer bg-lightBlue overflow-hidden pt-8 pb-8">
      <div className="container mx-auto">
        <div className="grid grid-cols-1  items-center lg:grid lg:grid-cols-3 lg:items-baseline md:grid md:grid-cols-2 md:items-baseline relative">
          {/* CONTACT US */}
          <div className="">
            <h3 className="pb-4 font-InterMedium font-semibold text-regularBlack text-center lg:text-left text-xl leading-7">
              Contact Us
            </h3>
            <div className="contact-us flex flex-col items-center lg:items-start space-y-7">
              <p className="font-InterRegular text-regularGrey text-sm">
                TANDA SECURE SCHOOL APP
              </p>
              <p className="flex flex-col font-InterRegular text-regularGrey text-sm lg:text-left">
                <span>+234 905 438 0854.</span>
                <span>info@tandasecure.com</span>
              </p>

              <p className="flex flex-col font-InterRegular text-regularGrey text-sm lg:text-left">
                <span>3 New Creation Street, Off Remi Olowude</span>
                <span> Street, Marwa Bus Stop, Lekki, Lagos.</span>
              </p>
            </div>
          </div>

          {/* QUICK LINKS */}
          <div>
            <h3 className="pb-4 font-InterMedium font-semibold text-regularBlack text-xl leading-7 text-center pt-8 lg:pt-0 lg:text-left">
              Quick Links
            </h3>
            <div className="contact-us flex flex-col items-center lg:items-start space-y-4">
              <a
                href={`${getBaseUrl()}`}
                className="font-InterRegular text-regularGrey text-sm hover:text-regularRed"
              >
                Home
              </a>
              <a
                href={`${getBaseUrl()}/product-features`}
                className="font-InterRegular text-regularGrey text-sm hover:text-regularRed"
              >
                Product Features
              </a>

              <a
                href={`${getBaseUrl()}/how-it-works`}
                className="flex flex-col font-InterRegular text-regularGrey text-sm hover:text-regularRed"
              >
                How it works
              </a>

              <a
                href={`${getBaseUrl()}/faq`}
                className="flex flex-col font-InterRegular text-regularGrey text-sm hover:text-regularRed"
              >
                FAQs
              </a>

              <a
                href={`${getBaseUrl()}/privacy_policy`}
                className="flex flex-col font-InterRegular text-regularGrey text-sm hover:text-regularRed"
              >
                Privacy Policy
              </a>
            </div>
          </div>

          {/* GET SUBSCRIBED */}
          <div hidden>
            <h3 className="pb-4 font-InterMedium font-semibold text-regularBlack text-xl leading-7 text-center lg:text-left lg:pt-0 pt-8">
              Get Subscribed
            </h3>
            <div className="contact-us flex flex-col items-center lg:items-start space-y-7">
              <p className="font-InterRegular text-regularGrey text-sm text-center w-4/5 lg:w-full lg:text-left">
                Enter your email address to subscribe to our mailing list
              </p>

              <input
                type={"email"}
                value={email}
                onChange={(e: any) => setEmail(e.target.value)}
                className="px-3 py-3 w-4/5 lg:w-full border border-regularWhite bg-regularWhite font-InterRegular text-regularGrey text-xs shadow-none outline-none placeholder:text-xs placeholder:text-regularGrey"
                placeholder="Email Address"
                name="email"
                id="email"
              />

              <button className="button-footer rounded-[3px] border border-solid border-regularGreen px-3 text-center py-3 font-InterMedium text-sm lg:text-base leading-4 bg-regularGreen text-regularWhite cursor-pointer font-bold lg:self-baseline">
                Subscribe
              </button>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
