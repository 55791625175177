import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { useLocation, useNavigate } from "react-router-dom";
import Biometric from "../../assets/images/Biometric.png";
import Dashboard from "../../assets/images/Dashboard.png";
import Laptop from "../../assets/images/Laptop-Group.png";
import AppleStore from "../../assets/images/app-store.png";
import GooglePlay from "../../assets/images/google-play.png";
import Mobile from "../../assets/images/mobile.png";
import Tanda from "../../assets/images/tanda.png";
import Footer from "../../component/Footer/footer";
import Header from "../../component/Header/Header";
import { registerUser } from "../../utils/baseUrl";
import "./productfeatures.scss";

const ProductFeatures = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: `${document.title}-Product Features`,
    });
  }, [location]);

  return (
    <React.Fragment>
      <Header query="Product Features" />
      <section
        className="product-features mt-6 pt-6 pb-10 mb-10 overflow-hidden"
        id="product-features"
      >
        {/* 1 */}
        <div className="container mx-auto flex flex-col-reverse md:flex md:flex-col-reverse lg:flex lg:flex-row lg:items-center lg:justify-between md:items-center mb-2">
          <div
            className="flex flex-col space-y-3 lg:w-1/2 lg:items-start"
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          >
            <h2 className="font-InterExtraBold font-normal lg:text-6xl md:text-base lg:leading-8 text-regularBlack w-full pb-4 text-align">
              Product <span className="text-regularRed">Features.</span>
            </h2>

            <p className="text-regularGrey font-normal font-InterRegular lg:text-base leading-6 lg:w-full paragraph-align padding">
              Tanda Secure is a tracking app for schools and parents to monitor
              students' movement in real-time. It includes a web application and
              mobile app for tracking and allows schools to capture and verify
              student data. The app is available on IOS and Android stores and
              allows students to clock in and clock out. An RFID Tag would be
              printed and it would grant parents access to the wards.
            </p>
          </div>

          <div
            className="lg:w-1/2 mx-auto mt-3 pb-5"
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          >
            <img src={Laptop} className="pl-3" alt="school bus" />
          </div>
        </div>

        {/* 2 */}
        <div className="lg:w-full h-parent img-bg m-top">
          <div className="container mx-auto flex flex-col md:flex md:flex-col lg:flex lg:flex-row lg:items-center height-100 lg:justify-between md:items-center">
            <div className="w-1/2">
              <img src={Mobile} className="hidden" alt="school bus" />
            </div>

            <div
              className="flex flex-col space-y-3 p-sm lg:w-1/2"
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            >
              <div className="watermark font-InterExtraBold text-lightBlue fixed top-0 right-0 -z-10 font-black">
                1
              </div>
              <h3 className="font-InterSemiBold font-semibold text-2xl h3">
                Parents App (Mobile Version)
              </h3>

              <p
                className="text-regularGrey font-normal font-InterRegular text-base pad text-size leading-6 lg:w-full"
                id="parent-app"
              >
                Parents install the TANDA SCHOOL SECURE APP from Google Play
                Store (Android) or App Store (iOS). The app registers the
                children's details and the clock-in and clock-out times while
                using the biometric ID card on the TANDA102 device. In addition,
                parents also receive instant alerts and updates about their
                children's school on their mobile devices.
              </p>

              <div
                className="flex flex-row items-center justify-center md:flex md:flex-row md:items-center md:justify-items-start lg:flex lg:flex-row lg:items-center lg:justify-start lg:space-x-2 pad-store justify-content"
                id="parent-app"
              >
                <a
                  className="no-underline"
                  href="https://play.google.com/store/apps/details?id=net.datainfosec.tanda_secure_app_parent"
                  rel="noreferrer"
                >
                  <img
                    src={GooglePlay}
                    className="h-7 lg:h-14 md:h-10 cursor-pointer"
                    alt="google play"
                  />
                </a>
                <a
                  className="no-underline"
                  href="https://apps.apple.com/app/tanda-secure-parent-app/id1611430266"
                  rel="noreferrer"
                >
                  <img
                    src={AppleStore}
                    className="h-7 lg:h-14 md:h-10 cursor-pointer"
                    alt="apple store"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* 3 */}
        <div className="lg:w-full h-3-parent img-3-bg m-3-top">
          <div className="container mx-auto flex flex-col md:flex md:flex-col lg:flex lg:flex-row lg:items-center height-100 lg:justify-between md:items-center ">
            <div
              className="flex flex-col space-y-8 p-sm lg:w-1/2 web-version"
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            >
              <div className="watermark font-InterExtraBold text-lightBlue fixed top-0 right-0 -z-10 font-black">
                2
              </div>
              <h3 className="font-InterSemiBold font-semibold text-2xl h3">
                School Portal (Web Version)
              </h3>

              <p className="text-regularGrey font-normal font-InterRegular text-base pad text-size leading-6 lg:w-full">
                The School Administration also installs the web version of the
                TANDA SCHOOL SECURE APP to register and monitor the check-in and
                check-out times. Meanwhile, it captures the data of parents or
                guardians responsible for the pick-up or collection of wards at
                home. As a result, the school administration can manage the
                attendance of students and pupils alongside other administrative
                duties. The app records the check-in and drop-off times of all
                students/pupils by the school bus. And all the data are
                synchronized in a central database with all entry times.
              </p>

              <div className="flex flex-row items-center justify-center md:flex md:flex-row md:items-center md:justify-items-start lg:flex lg:flex-row lg:items-center lg:justify-start lg:space-x-2 pad-store justify-content">
                <a
                  href={`${registerUser()}/signup`}
                  className="button rounded-[3px] border border-solid border-regularGreen px-3 text-center py-3 font-InterMedium text-sm lg:text-base leading-4 bg-regularGreen text-regularWhite cursor-pointer font-bold"
                  rel="noreferrer"
                  target={"_blank"}
                >
                  Go to school portal
                </a>
              </div>
            </div>
            <div className="w-1/2">
              <img src={Dashboard} className="hidden" alt="school bus" />
            </div>
          </div>
        </div>

        {/* 4 */}
        <div className="lg:w-full h-4-parent img-4-bg m-4-top">
          <div className="container mx-auto flex flex-col md:flex md:flex-col-reverse lg:flex lg:flex-row lg:items-center lg:justify-between md:items-center mb-2 biometric-position h-100">
            <div
              className="lg:w-1/2 mx-auto mt-3 pb-5"
              data-aos="fade-down"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            >
              <img
                src={Biometric}
                className="pl-3 max-width hidden"
                alt="school bus"
              />
            </div>

            <div
              className="flex flex-col space-y-3 lg:w-1/2"
              data-aos="fade-left"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
              id="bio-data"
            >
              <div className="watermark font-InterExtraBold text-lightBlue fixed top-0 right-0 -z-10 font-black">
                3
              </div>
              <h3 className="font-InterSemiBold font-semibold text-2xl h3">
                School Bio-data capture app
              </h3>

              <p className="text-regularGrey font-normal font-InterRegular lg:text-base leading-6 lg:w-full paragraph-align padding">
                Schools can use the biometric app for Android OS with a custom
                device for data capturing for all staff and students. As a
                result, all staff and students have biometric information
                profiles for managing every clock-in and clock-out.
              </p>

              <div className="flex flex-row items-center justify-center md:flex md:flex-row md:items-center md:justify-items-start lg:flex lg:flex-row lg:items-center lg:justify-start lg:space-x-2 pad-store justify-content">
                <a
                  className="no-underline"
                  href="https://play.google.com/store/apps/details?id=net.datainfosec.tandabiometricapp.tanda_biometric_app"
                  rel="noreferrer"
                >
                  <img
                    src={GooglePlay}
                    className="h-7 lg:h-14 md:h-10 cursor-pointer"
                    alt="google play"
                  />
                </a>

                <img
                  src={AppleStore}
                  className="h-7 lg:h-14 md:h-10 cursor-pointer"
                  alt="apple store"
                />
              </div>
            </div>
          </div>
        </div>

        {/* 5 */}
        <div className="container mx-auto flex flex-col-reverse md:flex md:flex-col-reverse lg:flex lg:flex-row lg:items-center lg:justify-between lg:mt-1 lg:pt-1 md:items-center mb-2 m-5-top m-6-top">
          <div
            className="flex flex-col space-y-7 lg:w-1/2"
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          >
            <div className="watermark font-InterExtraBold text-lightBlue fixed top-0 right-0 -z-10 font-black">
              4
            </div>
            <h3 className="font-InterSemiBold font-semibold text-2xl h3">
              Tanda 102
            </h3>

            <p className="text-regularGrey font-normal font-InterRegular lg:text-base leading-6 lg:w-full paragraph-align padding">
              The TANDA102 is a next generation durable IP65 mobile biometric
              device built specifically to work especially in remote and harsh
              conditions. It has the cutting edge IB FAP30 fingerprint scanner,
              5'' 720*1280 IPS LCD screen, 6000 mAH Battery, 2G RAM, 16G ROM,
              Qual Core CPU, 5Mp Camera. Running on Android 7.0+ OS, the
              TANDA102 provides the platform to deploy state of art Applications
              for all sectors of the economy using biometric registration and
              verification processes. It is also well suited for Time and
              Attendance purposes in Schools, Hospitals, Factories and most
              places of work.
            </p>

            <div className="flex flex-row items-center justify-center md:flex md:flex-row md:items-center md:justify-items-start lg:flex lg:flex-row lg:items-center lg:justify-start lg:space-x-2 pad-store justify-content">
              <a
                href="/contact-us"
                className="button rounded-[3px] border border-solid border-regularGreen px-3 text-center py-3 font-InterMedium text-sm lg:text-base leading-4 bg-regularGreen text-regularWhite cursor-pointer font-bold no-underline"
              >
                Get TANDA 102
              </a>
            </div>
          </div>

          <div className="lg:w-1/2 mx-auto mt-3 pb-5">
            <img
              src={Tanda}
              className="pl-3 tanda-image"
              alt="school bus"
              data-aos="fade-left"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            />
          </div>
        </div>
      </section>
      <Footer />
    </React.Fragment>
  );
};

export default ProductFeatures;
