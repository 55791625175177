import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import Dashboard from "../../assets/images/Dashboard.png";
import Laptop from "../../assets/images/how.png";
import ParentApp from "../../assets/images/parent-app.png";
import SchBiodata from "../../assets/images/sch-biodata.png";
import Footer from "../../component/Footer/footer";
import Header from "../../component/Header/Header";
import "./how.scss";

function How(): JSX.Element {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: `${document.title}-How it works`,
    });
  }, [location]);

  const howFisrtSection = () => {
    return (
      <section
        className="how-it-works overflow-hidden mt-10 pt-10 pb-10 mb-10"
        id="howItWorks"
      >
        <div className="lg:w-full lg:mt-5 h-secure-school secure-sch-img-bg secure-margin-top">
          <div className="container mx-auto flex flex-col-reverse md:flex md:flex-col-reverse lg:flex lg:flex-row lg:items-center lg:justify-between md:items-center mb-2 secure-height-100 secure-height-101">
            <div
              className="flex flex-col space-y-3 lg:w-1/2 text-center lg:text-left md:mt-7"
              // data-aos="fade-right"
              // data-aos-duration="1000"
              // data-aos-easing="ease-in-out"
            >
              <h2 className="font-InterExtraBold mt-5 font-normal text-center lg:text-6xl md:text-base md:text-center lg:leading-8 lg:mt-0 lg:text-left text-regularBlack w-full pb-4 text-alignn">
                How it <span className="text-regularRed">Works.</span>
              </h2>

              <p className="text-regularGrey font-normal font-InterRegular text-center mx-auto md:text-center lg:text-base leading-6 lg:w-10/12 lg:mx-0 md:w-4/5 md:mx-auto lg:text-left paragraph-align padding">
                Install, register and start tracking. Parents and school admins
                can monitor pupils/students pickup and drop off in real time.
                The application is available on the web, Android and IOS stores.
              </p>
            </div>

            <div
              className="lg:w-1/2 mx-auto mt-3 pb-5"
              data-aos="fade-left"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            >
              <img src={Laptop} className="" alt="school bus" />
            </div>
          </div>
        </div>
      </section>
    );
  };

  return (
    <React.Fragment>
      <Header query="How it Works" />
      <section className="overflow-hidden" id="how-new">
        {howFisrtSection()}
        {/* 2 */}
        <div className="lg:w-full h-4-parent img-45-bg m-45-top m-46-top">
          <div className="container mx-auto flex flex-col md:flex md:flex-col-reverse lg:flex lg:flex-row lg:items-center lg:justify-between md:items-center mb-2 biometric-position h-100">
            <div
              className="lg:w-1/2 mx-auto mt-3 pb-5"
              data-aos="fade-down"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            >
              <img
                src={Dashboard}
                className="pl-3 max-width hidden"
                alt="school bus"
              />
            </div>

            <div
              className="flex flex-col space-y-3 lg:w-1/2 relative"
              data-aos="fade-left"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
              id="bio-data"
            >
              <div className="watermark font-InterExtraBold text-lightBlue fixed top-0 right-0 -z-10 font-black">
                1
              </div>
              <h3 className="font-InterSemiBold font-semibold text-2xl h3">
                School Web Portal
              </h3>

              <p className="text-regularGrey font-normal font-InterRegular lg:text-base leading-6 lg:w-full paragraph-align padding">
                To start, visit the website and create an account, follow a
                sequence of steps that include entering personal and school
                information. Once logged in, users can set the school's opening
                and closing times and create sub-admins for the admin page.
              </p>
              <p className="text-regularGrey font-normal font-InterRegular lg:text-base leading-6 lg:w-full paragraph-align padding">
                To register parents and students, users can input the necessary
                information and generate unique codes for each. Ensure the right
                information and fields are properly filled.
              </p>
            </div>
          </div>
        </div>

        {/* 3 */}
        <div className="container mx-auto flex flex-col md:flex md:flex-col lg:flex lg:flex-row lg:items-center lg:justify-between md:items-center mb-2 secure-height-10000 secure-height-10111 lg:-mt-28">
          <div
            className="flex flex-col space-y-3 pt-0 lg:w-1/2 relative lg:-mt-52"
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            id="bio-data"
          >
            <div className="watermark font-InterExtraBold text-lightBlue fixed top-0 right-0 -z-10 font-black">
              2
            </div>
            <h3 className="font-InterSemiBold font-semibold text-2xl h3">
              Parents Mobile App
            </h3>

            <p className="text-regularGrey font-normal font-InterRegular lg:text-base leading-6 lg:w-full paragraph-align padding">
              To begin, download the mobile app from play store or app store and
              create an account with a default password that is sent via email.
              After changing the password, users can add guardians by uploading
              a picture and ID card, and can set reminders for pickups and
              drop-offs on the settings page.
              <br />
              <br /> The dashboard provides a detailed overview of the child's
              pickups and drop-offs, and real-time notifications are sent for
              each event. Additionally, the application allows users to delegate
              pickup and drop-off tasks to previously added guardians if
              necessary.
            </p>
          </div>

          <div
            className="lg:w-1/2 mx-auto pb-5"
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          >
            <img src={ParentApp} className="" alt="school bus" />
          </div>
        </div>

        {/* 4 */}
        <div className="container mx-auto flex flex-col md:flex md:flex-col lg:flex lg:flex-row lg:items-center lg:justify-between md:items-center mb-2 secure-height-10000 secure-height-10111">
          <div
            className="lg:w-1/2 mx-auto mt-3 pb-5"
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          >
            <img src={SchBiodata} className="" alt="school bus" />
          </div>
          <div
            className="flex flex-col space-y-3 lg:w-1/2 relative"
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            id="bio-data"
          >
            <div className="watermark font-InterExtraBold text-lightBlue fixed top-0 right-0 -z-10 font-black">
              3
            </div>
            <h3 className="font-InterSemiBold font-semibold text-2xl h3">
              School Admin App
            </h3>

            <p className="text-regularGrey font-normal font-InterRegular lg:text-base leading-6 lg:w-full paragraph-align padding">
              To begin, users must sign in with their school login credentials
              or sub admin details. To set up clock-in for students, users must
              select "scan" and scan the parent ID card, which generates a page
              with the parent's or guardian’s information and registered
              children.
              <br />
              <br /> School staff can then select the child or children to be
              picked up or dropped off and click "clock in/clock out" to
              complete the process. This feature can be used to manage regular
              pickups and drop-offs, school bus activity, or school exeat
              requests.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </React.Fragment>
  );
}

export default How;
