interface faqInterface {
  id: string;
  Q: string;
  A: string;
}

interface gridObject {
  id?: number;
  Q?: string;
  A?: string;
  img?: React.ReactNode;
}

export const data: faqInterface[] = [
  {
    id: "1",
    Q: " Q: Does the School have a Separate App?",
    A: " A: Yes, the School installs a biometric app onto a mobile device with which she captures the data. This app can capture real-time data stamps from the TANDA102 mobile with the biometric scanner.",
  },
  {
    id: "2",
    Q: "Q: How does the School Administration Use and Manage the App?",
    A: "A: The app has a SCHOOL PORTAL, managed entirely by the School Admin. This app is used to capture the data of all staff and students. These profiles form a background for data collection about check-in and out of staff and students.",
  },
  {
    id: "3",
    Q: "Q: What is the function of the School Mobile Device?",
    A: "This device is used for daily clock in/out of  students/pupils to and from school. Moreover, it is a portable device for moving from one place to another, especially in the school bus.",
  },
  {
    id: "4",
    Q: "Q.	How much does the TANDA SECURE SCHOOL APP Cost?",
    A: "The subscription fee for the TANDA SECURE SCHOOL APP is &#8358;5,000(Per Child per term) and a one off cost of &#8358;2,000 for ID card(Per Parent). However, there is also a one-time payment acquisition of N234,500 per Tanda102 Device and MiFare Card unit.",
  },
];

export const leftGrid: gridObject[] = [
  // {
  //   id: 1,
  //   Q: "Q.	What is the cost of the subscription?",
  //   A: "The subscription is ₦5,000 per child per term of 3 months. The service is cloud based and each clocking transaction is processed per child and the historical data is stored and can be searched and retrieved from the App.",
  // },
  // {
  //   id: 2,
  //   Q: "Q: What is the cost of the ID card?",
  //   A: "The RFID enabled ID card cost ₦2,000 per card per family for the academic session. (All members of a family share a card)",
  // },
  {
    id: 1,
    Q: "Q: Who manages and has access to the data collected?",
    A: `The school registers/manages the parents and children on the School’s Tanda Secure Web Admin Portal, while the parent registers/manages the guardians for drop-off/pickup and can upload pictures for Parents/Guardians/Children from the Parent’s Tanda Secure App. Both Parents and School receive notifications on drop-off/pickup operations in-App and via email.`,
  },
  {
    id: 2,
    Q: "Q: Is the information controlled by the school or by the organization?",
    A: "The data collected and processed are encrypted and visible only to the school administrator.",
  },
  {
    id: 3,
    Q: "Q.	What is the cost of the Tanda102 device?",
    A: "The Tanda102 device cost $350. It is a rugged device built specifically for outdoor operations and is shock-proof, water resistant, dust proof, has long battery-life to cope with the rigours of large-scale time and attendance operations.",
  },
  {
    id: 4,
    Q: "Q: Are there alternatives to the ID cards?",
    A: "Yes, in a true digital environment QR codes generated by the Tanda Secure App can be scanned in place of ID cards as the Parent/Guardian/Child data lives in the backend and not on the card.",
  },
  {
    id: 5,
    Q: "Q: Are there alternatives to the Tanda102 devices?",
    A: "Yes, a mid-range Android/iOS phone/tablet with NFC functionality can be used in place of the Tanda102 device. Though cheaper, the downsides are that it susceptible to damage and abuse by operators. Also it will not have the free Employee Biometric fingerprint Clock-in/out feature. These devices will be best suited for the school bus operation rather school entrance operation.",
  },
  {
    id: 6,
    Q: "Q: What are the other costs incurred by the school to provide the service?",
    A: "To ensure a seamless App operation, the school has to provide internet connectivity for the devices, provide competent staff to operate the devices and provide enough devices for their unique population of pupils/students. This will inform their final cost of subscription for each child.",
  },
  {
    id: 7,
    Q: "Q: Are there discounts on subscription for parents with more than 2 children?",
    A: "There will be a 25% discount for the third (3rd) child and thereafter in a family for a particular school.",
  },
  {
    id: 8,
    Q: "Q: Is there a subscription fee for Employee Time and Attendance?",
    A: "No, the Employee Time and Attendance is a free service included in the Tanda Secure App.",
  },
  {
    id: 9,
    Q: "Q: How are payments made?",
    A: `Subscriptions are collected by the school from parents and remitted to Tanda Secure via the different payment methods stated on School’s Tanda Secure Web Admin Portal.`,
  },
  {
    id: 10,
    Q: "Q: What are the requirement for a school to signup?",
    A: `Parent and student information: The app requires users to enter the personal information for the parents (Full name, email, phone number, role and image). Student (Full name, class and image).
School information: The school name, address, state,  and local government is required.
Supervisors information: The name, phone number, email and picture is required
Consent: The app require users or their parents/guardians to give their consent for the app's data privacy and security policies.
Verification: To ensure that only authorized users can access the attendance data, the app may require verification through an OTP or a confirmation link sent to the registered email or phone number.
Internet connectivity, mobile phones with nfc or custom Tanda device`,
  },
];

export const rightGrid: gridObject[] = [
  {
    id: 3,
    Q: "Q: How many devices does a school require for the clock-in/out operations?",
    A: "This depends on the number of pupils/students and the number of school buses in operation. Note that the clock-in/out operation takes less than 5 seconds per child. So calculations can be made on this basis to avoid long queues.",
  },
  {
    id: 4,
    Q: "Q: How is Tanda Secure different from the off the shelf products in the market?",
    A: "Tanda Secure App is unique in the sense that the Parent App gives more control to parents in real-time as to who drops-off/picks up their wards and grants more visibility to the school via the Web Admin dashboard. It is also very portable, durable and affordable.",
  },
  {
    id: 5,
    Q: "Q: How does the school bus operation work?",
    A: "The Parent/Guardian presents the family ID card/QR code and the Bus Guardian scans it on the Tanda102/Android Phone/Tablet with NFC feature and clocks in the child/children into the school bus from the home. On arrival at school premises the Bus Guardian clocks the child/children out of the school bus and into the school premises. At the end of the school day the Bus Guardian clocks the child/children into the school bus and on getting to the child’s home scans the family ID card/QR code presented by the Parent/Guardian to clock the child/children off the school bus to the safety of the home. In-App and email notifications are sent to the Parents and School at point of clock-n/out operation.",
  },
  {
    id: 6,
    Q: "Q: How does the parent/guardian drop-off/pickup operation work?",
    A: "The Parent/Guardian presents the family ID card/QR code and the School Guardian scans it on the Tanda102/Android Phone/Tablet with NFC feature and clocks in the child/children into the school premises. At the end of the school day the School Guardian scans the family ID card/QR code presented by the Parent/Guardian to clock the child/children off the school premises to the custody of the Parent/Guardian. In-App and email notifications are sent to the Parents and School at point of clock-n/out operation.",
  },
  {
    id: 7,
    Q: "Q: Would the price of the device be discounted if you had it for all the school buses?",
    A: "We are a software centric company and provide hardware at cost price and hence there is no discount of the Tanda102 devices. Rather we would recommend cheaper NFC enabled Android phones/tablets for the school bus run and Tanda102 devices only for school premises.",
  },
  {
    id: 8,
    Q: "Q: Is there an outright purchase of a solution?",
    A: "Tanda Secure is a paid subscription service and in not available for outright purchase.",
  },
  {
    id: 9,
    Q: "Q: What are the specifications of the Tanda102 hardware?",
    A: `Display 5” IPS LCD Screen
Resolution 720*1280 pixels
Touch Panel G+G Capacitive multi-touch
Operating System Android 7.0 or above
Processor/CPU Quad core 4G platform
Memory 1GB RAM + 8GB ROM
(2GB+16GB Optional)
Camera Rear: 5.0MP Auto Focus
Built-in Battery 6000MAH
Fingerprint IB FAP30
Housing IP65 with Wrap
SAM Support
Charging Port DC􀀂Micro USB
I/O Interface DCx1, SIMx2, Earphonex1,
Powerx1, Volume + - x1, TF x1 `,
  },
];
