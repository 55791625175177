import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import About from "./About/About";
import Choose from "./Choose/Choose";
import Faq from "./FAQ/Faq";
import Footer from "./Footer/footer";
import Header from "./Header/Header";
import Hero from "./Hero/Hero";
import How from "./How/How";
import Partners from "./Partners/Partners";
import Solution from "./Solution/Solution";

function Layout() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: `${document.title}-Home`,
    });
  }, [location]);

  return (
    <React.Fragment>
      <Header />
      <Hero />
      <Partners />
      <About />
      <Solution />
      <How />
      <Choose />
      <Faq />
      <Footer />
    </React.Fragment>
  );
}

export default Layout;
